import { get } from 'lodash';
import { initialState } from './initialState';
import * as constants from './constants';
import produce from 'immer';

// ===========================|| ACCOUNT REDUCER ||=========================== //

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.INIT_PROJECTS:
      return produce(state, (draft) => {
        const projects = action.payload;
        draft.projectIds = [];
        draft.projectsById = {};
        projects.forEach((project, idx) => {
          const { id } = project;
          draft.projectsById[id] = project;
          draft.projectIds.push(id);
          // Select the first project
          if (idx === 0) {
            draft.selectedProjectIds = [id];
            draft.selectedCasingIds = [];
            draft.selectedSensitivityIds = [];
          }
        });
      });

    case constants.SELECT_PROJECTS:
      return produce(state, (draft) => {
        const ids = action.payload;
        draft.selectedProjectIds = ids;
        draft.selectedCasingIds = [];
        draft.selectedSensitivityIds = [];
      });

    case constants.ADD_PROJECT:
      return produce(state, (draft) => {
        const { id } = action.payload;
        draft.projectsById[id] = action.payload;
        draft.projectIds.push(id);
        // // Insert new case to select
        // draft.selectedProjectIds.unshift(id);
        draft.selectedProjectIds = [id];
        draft.selectedCasingIds = [];
        draft.selectedSensitivityIds = [];
      });

    case constants.REMOVE_PROJECT:
      return produce(state, (draft) => {
        const id = action.payload;
        // Delete in obj and children obj
        delete draft.projectsById[id];
        delete draft.inputGeneralByProjectId[id];
        // Delete in order array
        const idx = draft.projectIds.findIndex((projId) => projId === id);
        if (idx !== -1) draft.projectIds.splice(idx, 1);
        // Delete in selectedProjectIds
        const selectId = draft.selectedProjectIds.findIndex((projId) => projId === id);
        if (selectId !== -1) draft.selectedProjectIds.splice(selectId, 1);
        draft.selectedCasingIds = [];
        draft.selectedSensitivityIds = [];
      });

    case constants.UPDATE_PROJECT:
      return produce(state, (draft) => {
        const { id } = action.payload;
        draft.projectsById[id] = action.payload;
      });

    case constants.UPDATE_PROJECT_INPUT_GENERAL:
      return produce(state, (draft) => {
        const obj = action.payload;
        const projectId = get(obj, 'project_id', null);
        if (projectId) {
          draft.inputGeneralByProjectId[projectId] = obj;
        }
      });

    case constants.UPDATE_PROJECT_INPUT_PRESSURE:
      return produce(state, (draft) => {
        const obj = action.payload;
        const projectId = get(obj, 'project_id', null);
        if (projectId) {
          draft.inputPressureByProjectId[projectId] = obj;
        }
      });

    case constants.UPDATE_PROJECT_INPUT_DRILLING_PROGRAM:
      return produce(state, (draft) => {
        const obj = action.payload;
        const projectId = get(obj, 'project_id', null);
        if (projectId) {
          draft.inputDrillingProgramByProjectId[projectId] = obj;
        }
      });

    case constants.UPDATE_PROJECT_OUTPUT:
      return produce(state, (draft) => {
        const obj = action.payload;
        const { output_casing_design: objA, output_drag_torque: objB, output_hydraulic: objC } = obj;
        const projectIdA = get(objA, 'project_id', null);
        if (projectIdA) {
          draft.outputCasingDesignByProjectId[projectIdA] = objA;
        }
        const projectIdB = get(objB, 'project_id', null);
        if (projectIdB) {
          draft.outputDragTorqueByProjectId[projectIdB] = objB;
        }
        const projectIdC = get(objC, 'project_id', null);
        if (projectIdC) {
          draft.outputHydraulicByProjectId[projectIdC] = objC;
        }
      });
    case constants.UPDATE_PROJECT_OUTPUT_CASING_DESIGN:
      return produce(state, (draft) => {
        const obj = action.payload;
        const projectId = get(obj, 'project_id', null);
        if (projectId) {
          draft.outputCasingDesignByProjectId[projectId] = obj;
        }
      });
    case constants.UPDATE_PROJECT_OUTPUT_DRAG_TORQUE:
      return produce(state, (draft) => {
        const obj = action.payload;
        const projectId = get(obj, 'project_id', null);
        if (projectId) {
          draft.outputDragTorqueByProjectId[projectId] = obj;
        }
      });
    case constants.UPDATE_PROJECT_OUTPUT_HYDRAULIC:
      return produce(state, (draft) => {
        const obj = action.payload;
        const projectId = get(obj, 'project_id', null);
        if (projectId) {
          draft.outputHydraulicByProjectId[projectId] = obj;
        }
      });
    case constants.SELECT_CASINGS:
      return produce(state, (draft) => {
        const ids = action.payload;
        draft.selectedCasingIds = ids;
        // Reset selection
        draft.selectedSensitivityIds = [];
        draft.selectedHdScenarioIds = [];
        draft.selectedCdScenarioIds = [];
        draft.selectedDtScenarioIds = [];
      });
    case constants.SELECT_MUDS:
      return produce(state, (draft) => {
        const ids = action.payload;
        draft.selectedMudIds = ids;
      });
    case constants.INIT_SENSITIVITIES:
      return produce(state, (draft) => {
        const sensitivites = action.payload;
        draft.sensitivityIds = [];
        draft.sensitivitiesById = {};
        draft.selectedSensitivityIds = [];
        sensitivites.forEach((sensitivity) => {
          const { id } = sensitivity;
          draft.sensitivitiesById[id] = sensitivity;
          draft.sensitivityIds.push(id);
        });
      });

    case constants.SELECT_SENSITIVITIES:
      return produce(state, (draft) => {
        const ids = action.payload;
        draft.selectedSensitivityIds = ids;
        draft.selectedHdScenarioIds = [];
        draft.selectedCdScenarioIds = [];
        draft.selectedDtScenarioIds = [];
      });

    case constants.ADD_SENSITIVITY:
      return produce(state, (draft) => {
        const { id } = action.payload;
        draft.sensitivitiesById[id] = action.payload;
        draft.sensitivityIds.push(id);
        draft.selectedSensitivityIds = [id];
      });

    case constants.REMOVE_SENSITIVITY:
      return produce(state, (draft) => {
        const id = action.payload;
        // Delete in obj
        delete draft.sensitivitiesById[id];
        // Delete in order array
        const idx = draft.sensitivityIds.findIndex((sensitivityId) => sensitivityId === id);
        if (idx !== -1) draft.sensitivityIds.splice(idx, 1);
        // Delete in selectedProjectIds
        const selectId = draft.selectedSensitivityIds.findIndex((sensitivityId) => sensitivityId === id);
        if (selectId !== -1) draft.selectedSensitivityIds.splice(selectId, 1);
      });

    case constants.UPDATE_SENSITIVITY:
      return produce(state, (draft) => {
        const { id } = action.payload;
        draft.sensitivitiesById[id] = action.payload;
      });

    case constants.UPDATE_CASING_DESIGN_SENSITIVITY:
      return produce(state, (draft) => {
        const { id } = action.payload;
        draft.cdSensitivitiesById[id] = action.payload;
      });

    case constants.UPDATE_HYDRAULIC_DESIGN_SENSITIVITY:
      return produce(state, (draft) => {
        const { id } = action.payload;
        draft.hdSensitivitiesById[id] = action.payload;
      });

    case constants.UPDATE_DRAG_TORQUE_SENSITIVITY:
      return produce(state, (draft) => {
        const { id } = action.payload;
        draft.dtSensitivitiesById[id] = action.payload;
      });

    case constants.SELECT_HD_SCENARIOS:
      return produce(state, (draft) => {
        const ids = action.payload;
        draft.selectedHdScenarioIds = ids;
      });

    case constants.SELECT_CD_SCENARIOS:
      return produce(state, (draft) => {
        const ids = action.payload;
        draft.selectedCdScenarioIds = ids;
      });

    case constants.SELECT_DT_SCENARIOS:
      return produce(state, (draft) => {
        const ids = action.payload;
        draft.selectedDtScenarioIds = ids;
      });

    case constants.SELECT_CURRENT_LAYOUT:
      return produce(state, (draft) => {
        const currentLayout = action.payload;
        draft.currentLayout = currentLayout;
      });

    case constants.UPDATE_SETTINGS:
      return produce(state, (draft) => {
        const settings = action.payload;
        draft.settings = settings;
      });

    default:
      return state;
  }
};

export default reducer;
